.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  &.no-padding {
    padding: 0;
  }

  &.small {
    max-width: 760px
  }
}

