$green: #20A84A;
$yellow: #FFE347;
$white: #fff;
$purple: #43358E;

.button {
  button {
    text-align: center;
    font-size: 14px;
    border: 2px solid transparent;
    color: #fff;
    min-width: 180px;
    transition: all 80ms ease;
    font-family: 'montserratbold';
    transition: all 0.18s ease;
    cursor: pointer;
    padding: 12px 20px 10px 20px;
    font-family: 'montserratbold';

    &:hover {
      -webkit-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
      -moz-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
      box-shadow: 0 8px 6px -6px #4141413a;
    }
  }

  &.no-hover {
    button {
      &:hover {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }

  &.large {
    button {
      width: 301px;
    }
  }

  &.fat {
    button {
      height: 76px;
      width: 100%;
    }
  }

  &.centered {
    display: inline-block
  }

  &.green {
    button {
      background: $green;

      &:hover {
        background: #fff;
        border: 2px solid $green;
        color: $green
      }
    }
  }

  &.green-ghost {
    button {
      background: transparent;
      border: 2px solid $green;
      color: $green;

      &:hover {
        background: $green;
        color: $white;
      }
    }
  }

  &.purple {
    button {
      background: $purple;

      &:hover {
        background: #fff;
        border: 2px solid $purple;
        color: $purple
      }
    }
  }

  &.purple-ghost {
    button {
      background: transparent;
      border: 2px solid $purple;
      color: $purple;

      &:hover {
        background: $purple;
        color: $white;
      }
    }
  }

  &.yellow {
    button {
      background: $yellow;
      color: #000;

      &:hover {
        background: #fff;
        border: 2px solid $yellow;
      }
    }
  }

  &.naked {
    button {
      text-align: left;
      transition: all 80ms ease;
      font-family: 'montserratbold';
      cursor: pointer;
      background: transparent;
      color: #20A84A;
      border: none;
      text-decoration: underline;
      padding: 0;
    }
  }
}

.button-list {
  display: flex;
  align-items: center;

  &.centered {
    justify-content: center
  }

  .button {
    margin: 0 15px 0 0;
  }


}

@media screen and (max-width: 768px) {

  .button-list {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .upload-btn-wrapper {
    margin: 0;
    width: 100%;
  }

  .button-list {
    flex-direction: column;
    width: 100%;

    div {
      width: 100%;
    }

    .button {
      margin: 0 0 15px 0;
    }
  }

  .upload-btn {
    width: 100%;
    max-width: 100%;

    button {
      width: 100%;
      max-width: 100%;
    }
  }

  .button {
    width: 100%;
    max-width: 100%;

    button {
      width: 100%;
      max-width: 100%;
    }
  }

  button {
    width: 100%;
    max-width: 100%;
  }
}