@import "react-alice-carousel/lib/scss/alice-carousel.scss";
$purple: #43358E;

.slider-container {
  position: relative;
}

.slider-item {
  padding: 60px 0
}

.alice-carousel__dots {
  position: absolute;
  bottom: 30px;
  width: 100%;
  margin: 0 !important;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  width: auto;
  transform: translateY(-50%)
}

.alice-carousel__dots-item {
  background-color: #B0ABC6 !important;
}

.alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
  background-color: $purple !important;

  &.white-icon {
    background-color: #fff !important;
  }
}

.slider-arrow {
  font-size: 30px;
  color: $purple;

  &.white-icon {
    color: #fff !important;
  }
}

.slider-next {
  position: absolute;
  top: 50%;
  right: 20px;
  text-align: right;
}

.slider-prev {
  position: absolute;
  top: 50%;
  left: 20px;
  text-align: left;
}

.slider-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2 {
    font-size: 30px;
    line-height: 50px;
    @media (min-width: 1400px){
      font-size: 40px;
    }
  }
}


.slider-content {
  width: 60%;

  &.full {
    width: 100%;
  }

  &.centered {
    text-align: center;
  }
}

@media screen and (max-width: 1600px){
  .slider-content {
    width: 50%;
  }
}

@media screen and (max-width: 1346px){
  .slider-content-container {
    padding: 0 70px;
  }
}

@media screen and (max-width: 1077px){
  .slider-content-container {
    padding: 0 30px;

  }
}


@media screen and (max-width: 768px){
  .slider-content {
    h2 {
      max-width: 90%;
      margin: 0 auto;
    }

    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 414px){
  .slider-item {
    padding: 0
  }
  .slider-content {
    h2 {
      max-width: 100%;
      margin: 0;
      
   
      font-size: 25px;
      line-height: 30px;
      padding: 10px 0;
   
    }
    text-align: left;
  }

  .slider-content-container {
    padding: 0;
  }
  
  // .alice-carousel__dots {
  //   display: none
  // }

  .slider-next {
    right: 10px;
  }

  .slider-prev {
    left: 10px;
  }
}

@media screen and (max-width: 370px){
  // .slider-content {
  //   p {
  //     display: none
  //   }
  // }
}