.video-detail {
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
}

.ui.embed {
  position: relative;
  max-width: 100%;
  height: 0;
  overflow: hidden;
  background: #dcddde;
  padding-bottom: 56.25%;
}

.ui.embed embed, .ui.embed iframe, .ui.embed object {
  position: absolute;
  border: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .video-detail {
    max-width: 100%;
    margin-bottom: 20px;
  }
}