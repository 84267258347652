section {
  margin: 60px 0 0 0;

  &#associations, &#value_proposition, &#mission_statement {
    margin-top: 60px;
  }

  &#service-form {
    margin-top: 60px;
  }

  &.no-margin {
    margin: 0;
  }

}

.section-header {
  text-align: center;
  margin:0 0 20px 0;


  &.width-auto {
    h2 {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &.no-padding {
    h2 {
      padding: 0;
    }
  }


  &.padding-top {
    h2 {
      margin: 0;
      margin-top: 30px;
    }
   
  }

  &.left-aligned {
    text-align: left;
  }

  h2 {
    max-width: 630px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px){
  section {
    margin: 60px 0 0 0;
  }
}

@media screen and (min-width: 1279px){
  section {
    &#service-content{
      margin: 180px 0 0 0;
    }
  }
}