.stat-card {
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;

  h2 {
    color: #fff;
    min-height: 103px;
  }

  h2.large {
    font-size: 80px;
    font-family: 'montserratbold';
    line-height: 102px;
    margin: 0;
  }

  h2.regular {
    margin-bottom :10px;
  }

 
  p {
    padding: 0;
  }

  p.large {
    font-family: 'montserratbold';
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 991px){
  .stat-card {
    min-height: 196px;
    margin-bottom: 30px;
  }
}