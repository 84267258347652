$purple: #43358E;


.footer-nav-second {
  padding-top: 20px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}

.footer-nav-second ul {
  margin: 0 0 20px 0;

  text-align: center;

  li {
    display: inline-block;
    padding: 0 12px;
    border-right: 1px solid #fff;
    color: #fff;

    a {
      color: #fff;
      font-size: 15px; 
    }

    &:first-child {
      padding-left: 0
    }

    &:last-child {
      border: none
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-nav-second {
    display: none
  }
}

@media screen and (max-width: 414px) {
  .footer-nav-second ul {
    max-width: 100%;
    margin: 0 20px 20px 20px ;
    text-align: left;
  
    li {
      display: block;
      padding: 0;
      border-right: none;
      color: #fff;
      font-family: 'montserratbold';
      a {
        color: #fff;
        font-size: 16px; 
    
      }
  
      &:first-child {
        padding-left: 0
      }
  
      &:last-child {
        border: none
      }
    }
  }
}