.top-feedback {
    color: #fff;
    height: 45px;
    width: 100%;
    background:#B71C1C;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-45px);
    transition: all 180ms;
}

.top-feedback.active {
    transition: all 180ms;
    transform: translateY(0px)
}

.top-feedback-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
}

.content {
    display: flex;
    align-items: center;
    flex-grow: 1   ;
    justify-content: center;

    svg {
        margin-right: 5px;
    }
}

.control-close {
    font-size: 19px;
    cursor: pointer;
}

