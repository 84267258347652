.vertical-slide-animation {
  position: relative;
  overflow: hidden;
  height: 30px;
  width: 100%;
  background: transparent;
  width: 260px;
}

.vertical-slider-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 7px;

  animation-delay: 2s;
}

.vertical-slider-two {
  animation: verticalCarouselTwo 10s infinite;
}

.vertical-slider-three {
  animation: verticalCarousel 7s infinite;
}

.vertical-slide-item {
  margin-bottom: 7px;
}


@keyframes verticalCarousel {
  0% { transform: translateY(0px); }
  10% { transform: translateY(0px); }
  20% { transform: translateY(-25px); }
  30% { transform: translateY(-25px); }
  40% { transform: translateY(-51px); }
  50% { transform: translateY(-51px); }
  60% { transform: translateY(-51px); }
  70% { transform: translateY(-25px); }
  80% { transform: translateY(-25px); }
  90% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

@keyframes verticalCarouselTwo {
  0% { transform: translateY(0px); }
  5% { transform: translateY(0px); }
  10% { transform: translateY(-25px); }
  15% { transform: translateY(-25px); }
  20% { transform: translateY(-51px); }
  25% { transform: translateY(-51px); }
  30% { transform: translateY(-75px); }
  35% { transform: translateY(-75px); }
  40% { transform: translateY(-80px); }
  45% { transform: translateY(-80px); }
  50% { transform: translateY(-100px); }
}