.map-responsive{
  position: relative;
  padding-bottom: 100%; // This is the aspect ratio
  height: 0;
  overflow: hidden
}
.map-responsive iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}