$purple: #43358E;
$borderGray: rgba(211, 211, 211, 0.26);
$green: #20A84A;

.navbar {
  border-top: 1px solid $borderGray;
  position: relative;
  // border-bottom: 1px solid $borderGray;

  .navbar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
  }

  .nav-list-container {
    border-top: 1px solid $borderGray
  }

  .nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .nav-item {
      display: flex;
      cursor: pointer;
      justify-content: center;

      .nav-item-link {
        cursor: pointer;
        font-family: 'montserratbold';
        display: block;
        padding: 28px 0;
        position: relative;
        overflow: hidden;
        font-size: 14px;
        color: $purple;
        
        &:after {
          content: '';
          position: absolute;
          top: calc(100% - 4px);
          left: 0;
          transform: translateY(110%);
          width: 100%;
          height: 4px;
          background: $green;
          transition: transform 80ms ease;
          backface-visibility: hidden;
        }
  
        &:hover {
          color: $purple;

          &:after {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .button {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px){
  .navbar {
    .navbar-header {
      display: flex;
      justify-content: space-between;
    }

    .nav-list {
      display: none;
    }
    
    &.is-active {
      .nav-list {
        display: block;
      }
    }
    
    .nav-list {
      flex-direction: column;

      .nav-item {
        text-align: center;
        width: 100%;
        border-bottom: 1px solid $borderGray;
        &:nth-child(7), &:nth-child(8),  &:nth-child(9) {
         padding-bottom: 0;
        }

        .button.yellow.three-in-one-btn {
          margin-top: 20px;
        }

        .nav-item-link {
          padding: 15px 0;

          &:hover {
            color: $purple;
  
            &:after {
              opacity: 0;
              transform: translateY(110%);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px){
  .navbar {
    .navbar-header {
      height: 100px;
    }
    .nav-list {
      .nav-item {
        flex-direction: column;

        .button {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}