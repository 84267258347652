footer {
  margin-bottom: 30px;
}

#associations {
    img {
      height: 90px;
      padding: 10px;
      margin: 0 0 20px 0;
    }
  .height97{
    height: 97px;
  }
  .height80{
    height: 80px;
  }
}

@media screen and (max-width: 414px){
#associations {
  li {
    width: 100%;
  }

  img {
    width: 60%;
    height: auto;
    margin: 0 auto;
  }
}
}