@import './FormReset.scss';
$green: #20A84A;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
}

.upload-btn-wrapper {
  position: relative;
/*  overflow: hidden;*/
  display: inline-block;
  /*margin-right: 20px;
  margin-top: 5px;*/
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.upload-btn {
  text-align: center;
  font-size: 14px;
  border: 2px solid $green;
  background: transparent;
  color: $green;
  min-width: 200px;
  transition: all 80ms ease;
  font-family: 'montserratbold';
  transition: all 0.18s ease;
  cursor: pointer;
  padding: 12px 35px 10px 35px;
  font-family: 'montserratbold';
  display:inline;

  &:hover {
    -webkit-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    -moz-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    background: transparent;
    border: 2px solid $green;
    color: $green
  }
}

.upload-btn-wrapper input[type=file] {
  cursor: pointer;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.proof-of-address::after{
    content:"\a (Not older than 3 months)";
    white-space: pre;
}

.copy-of-bank-letter::after{
    content:"\a (Not older than 3 months; PDF only) (optional)";
    white-space: pre;
}

.company-resolution-form::after{
    content:"\a (PDF only)";
    white-space: pre;
}

// input[type="file"]{
//   border: none;
//   padding: 0;
//   max-width: 200px;
//   margin-right: 20px;
//   color: transparent;
//   color: transparent;
//   position: relative;

//   &.after {
//     position: absolute;
//     content: '';
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: green
//   }
// }

label {
  width: 100%;
  color: #000000;
  text-align: left;
  margin-bottom: 5px;
  font-family: 'montserratbold';
}

::-webkit-input-placeholder {
  /* Edge */
  color: #000000;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

::placeholder {
  color: #000000;
}

::-webkit-file-upload-button {
  text-align: center;
  font-size: 14px;
  border: 2px solid transparent;
  background: $green;
  color: #fff;
  min-width: 200px;
  transition: all 80ms ease;
  font-family: 'montserratbold';
  transition: all 0.18s ease;
  cursor: pointer;
  padding: 12px 35px 10px 35px;
  font-family: 'montserratbold';

  &:hover {
    -webkit-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    -moz-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
    background: transparent;
    border: 2px solid $green;
    color: $green
  }
}

.form-group {
  margin-bottom: 20px;

  &.checkbox {
    display: flex;
    flex-direction: row-reverse;
    height: 37px;
    // align-items: center;

    input {
      margin-right: 10px;
    }

    label {
      margin: 0;
      font-family: Arial!important;
    }
  }

  &.no-margin {
    margin: 10px 0;
    height: auto;
  }
}

.error-message {
  color: red;
  text-align: left;
  font-size: 12px;
  padding-top: 10px;
}

.field.error input,
.field.error textarea {
  border: 1px solid red;
}

.form-footer {
  display: flex;
  justify-content: space-between;

  .form-footer-item {
    width: 48%;
  }

  &.centered {
    display: flex;
    justify-content: center
  }
}

.g-recaptcha div {
  transform: scale(.92);
  transform-origin: 0 0;
}

@media screen and (max-width: 414px) {
  .g-recaptcha div {
    transform: scale(1);
    transform-origin: 0 0;
  }

  .form-footer {
    flex-direction: column;

    .form-footer-item {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

._field-group{
  @media(min-width: 800px){
    display: flex;
    column-gap: 20px;
    .field {
      width: 100%;
    }
  }
}