.privacy-policy{
  .terms-list {
    .pp_text-100{
      margin-left: 100px;
    }
    table{
      display: block;
      max-width: -moz-fit-content;
      max-width: fit-content;
      overflow-x: auto;
      width: 100%;
      margin-bottom: 30px;
      @media(min-width: 1000px){
        margin-left: 100px;
      }
    }
    td{
      border: 1px solid #ccc;
      padding: 10px;
    }
    li {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      p {
        width: 90%;
        strong {
          width: auto;
        }
      }
      strong {
        color: #43358E;
        font-weight: 600;
        display: block;
        width: 100px;
        line-height: 26px;
      }
      span{
        width: auto!important;
        color: inherit;
        font-weight: inherit;
      }
    }
  }
}


.privacy-policy_table, .privacy-policy_table tr, .privacy-policy_table td, .privacy-policy_table th{
  border-collapse: collapse;
  border: 1px solid #ccc;
}
.privacy-policy_table td, .privacy-policy_table th{
  padding: 20px;
}