.contact-detail {
  text-align: center;
  min-height: 112px;

  h3 {
    padding-bottom: 10px;
  }

  p {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1023px){
  .contact-detail {
    min-height: 110px;
  }
}

@media screen and (max-width: 411px){
  .contact-detail {
    min-height: auto
  }
}